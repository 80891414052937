@import url('https://fonts.googleapis.com/css2?family=Atma:wght@300;400;500;600;700&family=Kalnia:wght@100..700&family=Mulish:ital,wght@1,300&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
    font-family: "Kalnia", serif;
    margin: 0;
    padding: 0;
    background: url('./Imgs/background3.png') repeat; /* Set your desired background image */
    background-size: auto; /* Default size for tiling */
    color: #fff; /* Optional: Set a default text color that contrasts with the background */
}

.banner {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
    display: block; /* Remove any unwanted space below the image */
}

/* Navbar styling */
.navbar {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 20px;
}

.navbar ul {
    list-style: none;
    padding: 0;
    display: flex;
    gap: 15px;
}

.navbar li {
    cursor: pointer;
}

.nav-btn {
   
    background-color: transparent; /* Ensure no background color */
    border: none; /* Remove any border */
    transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth transition for opacity and scaling */
    opacity: 0.5; /* Default opacity for non-selected buttons */
}

/* Selected button style */
.nav-btn.selected, .nav-btn:hover {
    opacity: 1; /* Full opacity for selected or hovered button */
    transform: scale(1.1); /* Slightly scale up for visual effect */
}

/* Style for the component container */
.component-container {
    padding: 20px;
}

h2 {
    font-size: 30px;
    font-weight: bold;
    padding: 20px;
}

.pixel-art-container {
    display: flex;
    flex-direction: column; /* Stack images vertically */
    align-items: center; /* Center align images horizontally */
}

.pixel-art-pic {
    width: 40%;
    margin-bottom: 20px;
}

/* Ensure the table takes the full width of its container */
.games-table {
    width: 100%;
    border-collapse: collapse; /* Optional: To remove spacing between cells */
}

/* Ensure each cell takes up 50% of the row */
.games-table td {
    width: 50%;
    padding: 10px; /* Optional: Add some padding */
    vertical-align: top; /* Align the content to the top of the cell */
}

.games-table-row {
    background-color: rgba(0, 0, 0, 0.3);
}

/* Style for the image cells */
.games-image-cell {
    text-align: center;
}

/* Style the images */
.games-image {
    width: auto; /* Set the width of the images */
    height: 100%; /* Maintain the aspect ratio */
}

/* Style for the text cells */
.games-text-cell h1 {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #f9c22b;
}

.games-text-cell p {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 600;
}

.games-text-cell a {
    color: #fbff86;
    text-decoration: none;
    font-weight: bold;
    font-size: 20px;
}

.games-text-cell a:hover {
    text-decoration: underline;
}


.games-image {
    width: 50%;
    height: auto; /* Maintain the aspect ratio */
    display: block; /* Make the image a block element */
    margin: 0 auto; /* Center the image within the cell */
}

.music-table {
    /* width: 100%; */
    border-collapse: collapse; /* Optional: To remove spacing between cells */
    text-align: center; /* Center the content within the table */
    margin: 0 auto; /* Center the table within its container */
    
}

/* Ensure each cell takes up 33.33% of the row and add padding */
.music-cell {
    width: 33.33%;
    padding: 10px;
    vertical-align: top; /* Align the content to the top of the cell */
    text-align: center; /* Center the content within the cell */
}

/* Optional: Add specific styles for the music embeds if needed */
.music-cell iframe {
    width: 100%; /* Ensure the iframes take the full width of the cell */
    height: auto; /* Maintain the aspect ratio */
}

/* Footer.css */
.footer {
    background-color: rgba(0, 0, 0, 0.5); /* Dark background color */
    color: white; /* Light text color */
    text-align: center; /* Center-align text */
    padding: 20px; /* Add some padding */
     /* Fixed position at the bottom */
    bottom: 0; /* Align to the bottom */
    width: 100%; /* Full width */
    box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.3); /* Optional shadow for better visibility */
}

.footer-btns {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
}

.footer-btns li {
    margin: 0 10px;
}

.footer-btns img {
    width: 50%; /* Adjust size as needed */
    height: auto; /* Adjust size as needed */
    opacity: 0.5;
    transition: opacity 0.3s;
}

.footer-btns img:hover {
    opacity: 1;
}


